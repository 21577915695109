const videoQualityMonitoringList = [
  {
    title: 'audienceAnalytics.section4.list1.item1.title',
    content: 'audienceAnalytics.section4.list1.item1.content'
  },
  {
    title: 'audienceAnalytics.section4.list1.item2.title',
    content: 'audienceAnalytics.section4.list1.item2.content'
  },
  {
    title: 'audienceAnalytics.section4.list1.item3.title',
    content: 'audienceAnalytics.section4.list1.item3.content'
  }
]

export default videoQualityMonitoringList
